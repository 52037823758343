import axios from "axios";
import envs from "../../env";
import globalStore from "../store/index";
import { getHeaders } from "../helpers/store_utils";
import DateFormater from "../helpers/date_formater";

var store = globalStore.state.verticals;

export const readFileHeaders = async () => {
	var formData = new FormData();
	formData.append("files", store.view.file, store.view.file.name);

	const { data } = await axios.post(
		`${envs.API_URL}vertical/read_file_headers`,
		formData,
		{
			headers: getHeaders({ rootState: globalStore.state }),
		}
	);
	return data.body;
};

export const processData = async () => {
	var formData = new FormData();
	formData.append("files", store.view.file, store.view.file.name);
	formData.append("fileHeaders", JSON.stringify(store.view.headers));

	const { data } = await axios.post(
		`${envs.API_URL}vertical/data_proccess`,
		formData,
		{
			headers: getHeaders({ rootState: globalStore.state }),
		}
	);
	return data.body;
};

export const evaluateTerms = async () => {
	const { data } = await axios.get(`${envs.API_URL}vertical/evaluate_terms`, {
		headers: getHeaders({ rootState: globalStore.state }),
	});

	loadMissings();

	return data;
};

export const loadMissings = async () => {
	const { data } = await axios.get(`${envs.API_URL}vertical/load_missings`, {
		headers: getHeaders({ rootState: globalStore.state }),
	});

	DateFormater.multiline(data.body, true);
	store.list = data.body;

	return true;
};

export const loadTerms = async () => {
	const { data } = await axios.get(`${envs.API_URL}vertical/load_terms`, {
		headers: getHeaders({ rootState: globalStore.state }),
	});

  DateFormater.multiline(data.body, true);
	store.list = data.body;

	return true;
};

export const loadWords = async () => {
	const { data } = await axios.get(`${envs.API_URL}vertical/load_words`, {
		headers: getHeaders({ rootState: globalStore.state }),
	});

  DateFormater.multiline(data.body, true);
	store.list = data.body;

	return true;
};

export const loadVerticals = async () => {
	const { data } = await axios.get(`${envs.API_URL}vertical/load_verticals`, {
		headers: getHeaders({ rootState: globalStore.state }),
	});

	store.list = data.body;

	return true;
};

export const saveWord = async () => {
	const { data } = await axios.post(
		`${envs.API_URL}vertical/save_word`,
		{
			word: store.view.word,
		},
		{
			headers: getHeaders({ rootState: globalStore.state }),
		}
	);
	return true;
};
export const saveMissing = async () => {
	const { data } = await axios.post(
		`${envs.API_URL}vertical/save_missing`,
		{
			term: store.view.term,
		},
		{
			headers: getHeaders({ rootState: globalStore.state }),
		}
	);
	return true;
};

export const saveTerm = async () => {
	await axios.post(
		`${envs.API_URL}vertical/save_term`,
		{
			term: store.view.term,
		},
		{
			headers: getHeaders({ rootState: globalStore.state }),
		}
	);
	return true;
};

export const loadWord = async (id) => {
	const { data } = await axios.get(`${envs.API_URL}vertical/load_word/${id}`, {
		headers: getHeaders({ rootState: globalStore.state }),
	});

	store.view.word = data.body;

	return true;
};

export const loadTerm = async (id) => {
	const { data } = await axios.get(`${envs.API_URL}vertical/load_term/${id}`, {
		headers: getHeaders({ rootState: globalStore.state }),
	});

	store.view.term = data.body;

	return true;
};

export const loadMissing = async (id) => {
	const { data } = await axios.get(
		`${envs.API_URL}vertical/load_missing/${id}`,
		{
			headers: getHeaders({ rootState: globalStore.state }),
		}
	);

	store.view.term = data.body;

	return true;
};
