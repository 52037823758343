<template>
	<div class="global-menu">
		<div
			:class="checkActive('upload')"
			@click="goTo('settings-vertical-activity-rel-upload')">
			{{ $t("labels.bulkUpload") }}
		</div>
		<div
			:class="checkActive('activities')"
			@click="goTo('settings-vertical-activity-rel')">
			{{ $t("labels.activeCnaeMenu") }}
		</div>
		<div
			:class="checkActive('words')"
			@click="goTo('settings-vertical-activity-rel-word-list')">
			{{ $t("labels.keywords") }}
		</div>
	</div>
</template>

<script>
import { SETTINGS_ROUTES } from "../../../router/settings";

export default {
	computed: {
		active() {
			const currentNameRoute = this.$route.name;
			if (currentNameRoute == SETTINGS_ROUTES.VERTICALS_REL_INDEX) {
				return "activities";
			}
		},
	},
	methods: {
		async goTo(route) {
			this.$router.push({
				name: route,
			});
		},
		checkActive(page) {
			var routeRel;
			const currentNameRoute = this.$route.name;
			if (
				currentNameRoute == SETTINGS_ROUTES.VERTICALS_REL_INDEX ||
				currentNameRoute == SETTINGS_ROUTES.VERTICALS_REL_TERMS ||
				currentNameRoute == SETTINGS_ROUTES.VERTICALS_REL_TERM_EDIT ||
				currentNameRoute == SETTINGS_ROUTES.VERTICALS_REL_MISSING_EDIT
			) {
				routeRel = "activities";
			} else if (currentNameRoute == SETTINGS_ROUTES.VERTICALS_REL_UPLOAD) {
				routeRel = "upload";
			} else {
				routeRel = "words";
			}

			if (routeRel == page) {
				return ["active"];
			} else {
				return [];
			}
		},
	},
};
</script>

<style lang="scss">
.global-menu {
	display: flex;
	justify-content: space-around;
	background-color: #f0b82e;

	& > div {
		padding: 12px;
		border: 1px solid #f0b82e;
		width: 100%;
		text-align: center;
		cursor: pointer;

		&:hover,
		&.active {
			background: #fdc131;
		}

		&.active {
			font-weight: bold;
		}
	}
}
</style>
