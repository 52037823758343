<template>
	<div>
		<div v-if="$route.query.upload" class="msg-info">
			{{ $t("pages.settings.verticalActivityRel.message:recentFileSend") }}
		</div>

		<f-card>
			<v-data-table
				:headers="headers"
				:items="list"
				@click:row="edit"
				:search="search">
				<template v-slot:top>
					<v-row class="d-flex align-end flex-column">
						<v-col cols="4">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-inner-icon="mdi-magnify" />
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</f-card>
	</div>
</template>

<script>
import { loadMissings, load } from "./../../../services/verticals";
import { SETTINGS_ROUTES } from "./../../../router/settings";

export default {
	data() {
		return {
			search: "",
			headers: [
				{
					text: this.$t("labels.activityWithoutClassification"),
					align: "start",
					sortable: true,
					value: "data",
				},
				{
					text: this.$t("labels.createdAt"),
					value: "created_at",
					align: "end",
				},
			],
		};
	},
	computed: {
		list() {
			return this.$store.state.verticals.list;
		},
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			loadMissings();
		},
		edit({ id }) {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_MISSING_EDIT,
				params: { id },
			});
		},
	},
};
</script>

<style lang="scss">
.msg-info {
	background-color: #cccccc;
	margin-bottom: 12px;
	padding: 12px 24px;
	border-radius: 4px;
}
</style>
