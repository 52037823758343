<template>
	<div>
		<global-menu />

		<f-default-header
			:title="$t('pages.settings.subtitle.activeCnae')"
			:subtitle="$t('pages.settings.verticalActivityRel.subtitle.activity')" />

		<div
			v-html="$t('pages.settings.verticalActivityRel.help.manageActiveCnae')"
			class="status-alert"></div>

		<missings-list />

		<f-actions-btn-holder>
			<v-btn elevation="0" large @click="evaluate">
				{{ $t("labels.btnEvaluate") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="goToAll">
				{{ $t("labels.btnSeeActive") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import GlobalMenu from "./../../../components/settings/verticalActivityRel/GlobalMenu.vue";
import MissingsList from "./../../../components/settings/verticalActivityRel/MissingsList.vue";
import { SETTINGS_ROUTES } from "../../../router/settings";
import { evaluateTerms } from "./../../../services/verticals";

export default {
	components: {
		MissingsList,
		GlobalMenu,
	},
	methods: {
		async goToAll() {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_TERMS,
			});
		},
		evaluate() {
			evaluateTerms();
		},
	},
};
</script>

<style lang="scss">
.status-alert {
	background-color: #cccccc;
	border-radius: 3px;
	margin-bottom: 24px;
	padding: 12px 24px;
	font-weight: normal;
}
</style>
